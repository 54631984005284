import { props, events, methods } from "./columns";
import checkPassword from "@/views/my-components/xboot/check-password";
export default {
  components: {
    checkPassword
  },
  data() {
    return {
      props: props,
      events: events,
      methods: methods,
      data1: [{
        name: "on-success",
        type: "仅当验证密码正确触发回调",
        value: "true"
      }],
      data2: [{
        name: "show",
        type: "显示密码验证组件",
        value: "无"
      }]
    };
  },
  methods: {
    showCheckPass() {
      this.$refs.checkPass.show();
    },
    checkSuccess() {
      this.$Message.success("验证成功");
    }
  },
  mounted() {}
};