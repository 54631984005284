import { props, events, methods } from "./columns";
import processChoose from "@/views/my-components/xboot/process-choose";
export default {
  components: {
    processChoose
  },
  data() {
    return {
      props: props,
      events: events,
      methods: methods,
      showProcessChoose: false,
      data1: [{
        name: "value",
        desc: "绑定的值，可使用 v-model 双向绑定",
        type: "String",
        value: "空"
      }, {
        name: "actBusinessId",
        desc: "流程关联业务表id，显示组件前务必给该属性传入actBusinessId数据值",
        type: "String",
        value: "空"
      }],
      data2: [{
        name: "on-submit",
        type: "Boolean",
        value: "仅成功提交申请触发返回true，用于刷新表单显示审批状态"
      }]
    };
  },
  methods: {},
  mounted() {}
};