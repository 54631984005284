var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card-info"
  }, [_c('span', {
    staticClass: "lstick"
  }), _c('div', {
    staticClass: "info-wrap"
  }, [_vm.icon ? _c('Icon', {
    staticClass: "icon",
    attrs: {
      "type": _vm.icon,
      "color": _vm.iconColor,
      "size": _vm.iconSize
    }
  }) : _c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.image,
      "width": _vm.width,
      "height": _vm.height
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('span', {
    style: {
      color: _vm.titleColor,
      fontSize: _vm.titleSize,
      fontWeight: _vm.titleWeight
    }
  }, [_vm._v(_vm._s(_vm.title))]), _c('countUp', {
    staticClass: "mt",
    attrs: {
      "prefix": _vm.prefix,
      "suffix": _vm.suffix,
      "id": _vm.id,
      "end-val": _vm.endVal,
      "color": _vm.countColor,
      "countSize": _vm.countSize,
      "countWeight": _vm.countWeight,
      "decimalPlaces": _vm.decimalPlaces,
      "duration": _vm.duration,
      "delay": _vm.delay,
      "options": _vm.options
    }
  })], 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };