var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Cascader', {
    attrs: {
      "data": _vm.department,
      "load-data": _vm.loadData,
      "change-on-select": "",
      "size": _vm.size,
      "transfer": _vm.transfer,
      "transfer-class-name": _vm.transferClassName,
      "disabled": _vm.disabled,
      "filterable": _vm.filterable,
      "clearable": _vm.clearable,
      "placeholder": _vm.placeholder
    },
    on: {
      "on-change": _vm.handleChangeDep,
      "on-visible-change": _vm.handleVisibleChange
    },
    model: {
      value: _vm.selectDep,
      callback: function ($$v) {
        _vm.selectDep = $$v;
      },
      expression: "selectDep"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };