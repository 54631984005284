var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("计数组件")]), _c('countUp', {
    ref: "count",
    attrs: {
      "endVal": _vm.value,
      "duration": 3
    }
  }), _c('Button', {
    staticStyle: {
      "margin-right": "10px"
    },
    on: {
      "click": _vm.start
    }
  }, [_vm._v("开始计数")]), _c('Button', {
    on: {
      "click": _vm.update
    }
  }, [_vm._v("更新值")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };