var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("素材中心")]), _c('Button', {
    on: {
      "click": function ($event) {
        _vm.showCenter1 = true;
      }
    }
  }, [_vm._v("打开图片素材中心")]), _c('br'), _c('br'), _c('Button', {
    on: {
      "click": function ($event) {
        _vm.showCenter2 = true;
      }
    }
  }, [_vm._v("打开视频素材中心")]), _c('br'), _c('br'), _vm._v(" " + _vm._s(_vm.fileInfo) + " "), _c('materialCenter', {
    attrs: {
      "multiple": ""
    },
    on: {
      "on-change": _vm.selectFile
    },
    model: {
      value: _vm.showCenter1,
      callback: function ($$v) {
        _vm.showCenter1 = $$v;
      },
      expression: "showCenter1"
    }
  }), _c('materialCenter', {
    attrs: {
      "mode": "视频",
      "videoId": "videoTest"
    },
    on: {
      "on-change": _vm.selectFile
    },
    model: {
      value: _vm.showCenter2,
      callback: function ($$v) {
        _vm.showCenter2 = $$v;
      },
      expression: "showCenter2"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定（组件显示）。"), _c('br'), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };