var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("图片上传缩略图")]), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v("直接上传")]), _c('upload-pic-thumb', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.picUrls,
      callback: function ($$v) {
        _vm.picUrls = $$v;
      },
      expression: "picUrls"
    }
  }), _c('br'), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v("素材中心上传")]), _c('upload-pic-thumb', {
    attrs: {
      "material": "",
      "multiple": "",
      "height": "100px",
      "width": "100px"
    },
    model: {
      value: _vm.picUrls,
      callback: function ($$v) {
        _vm.picUrls = $$v;
      },
      expression: "picUrls"
    }
  }), _c('br'), _vm._v(" " + _vm._s(_vm.picUrls) + " "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("提示")]), _vm._v(" 可拖拽实现交换图片顺序。可自定义图片框长和宽。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };