import countUp from "@/views/my-components/widget/countUp";
export default {
  name: "card2",
  components: {
    countUp
  },
  props: {
    id: String,
    icon: String,
    iconSize: {
      type: [Number, String],
      default: 50
    },
    iconColor: {
      type: String,
      default: "#478ef9"
    },
    image: {
      type: String,
      default: require("@/assets/dashboard/empty.png")
    },
    width: {
      type: String,
      default: "55px"
    },
    height: {
      type: String,
      default: "55px"
    },
    prefix: String,
    suffix: String,
    endVal: Number,
    decimalPlaces: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 2
    },
    delay: {
      type: Number,
      default: 0
    },
    options: {
      type: Object,
      default: () => {
        return {
          startVal: 0,
          useEasing: true,
          useGrouping: true,
          separator: ",",
          decimal: "."
        };
      }
    },
    countColor: {
      type: String,
      default: "#3f4255"
    },
    countSize: {
      type: String,
      default: "24px"
    },
    countWeight: {
      type: Number,
      default: 400
    },
    title: String,
    titleColor: {
      type: String,
      default: "#99abb4"
    },
    titleSize: {
      type: String,
      default: "14px"
    },
    titleWeight: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};