var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("图标选择输入框")]), _c('icon-choose', {
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "showCustom": "",
      "showInput": ""
    },
    model: {
      value: _vm.icon,
      callback: function ($$v) {
        _vm.icon = $$v;
      },
      expression: "icon"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("提示")]), _vm._v(" 使用iconfont或自定义图片链接建议配合"), _c('code', [_vm._v("XIcon")]), _vm._v("组件一起使用。扩展图标信息添加见 "), _c('code', [_vm._v("src/libs/icon.js")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };