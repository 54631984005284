var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("地图选点组件")]), _c('Alert', {
    attrs: {
      "type": "info",
      "show-icon": ""
    }
  }, [_vm._v(" 基于 "), _c('a', {
    attrs: {
      "href": "https://docs.mapbox.com/mapbox-gl-js/api/",
      "target": "_blank"
    }
  }, [_vm._v("Mapbox GL JS")]), _vm._v(" 封装，已配置好中文、属性配置等。为何不选择国内地图产品？- 国内产品针对非公众项目时需进行商用授权： "), _c('a', {
    attrs: {
      "href": "https://developer.amap.com/upgrade#business",
      "target": "_blank"
    }
  }, [_vm._v("高德")]), _vm._v("、 "), _c('a', {
    attrs: {
      "href": "http://lbsyun.baidu.com/cashier/auth",
      "target": "_blank"
    }
  }, [_vm._v("百度")]), _vm._v("、 "), _c('a', {
    attrs: {
      "href": "https://lbs.qq.com/dev/console/authorization",
      "target": "_blank"
    }
  }, [_vm._v("腾讯")]), _vm._v("。 Mapbox按量付费，支持全球国际化地图，每月有一定"), _c('a', {
    attrs: {
      "href": "https://www.mapbox.com/pricing",
      "target": "_blank"
    }
  }, [_vm._v("免费额度")])]), _c('mapLocate', {
    staticStyle: {
      "width": "400px"
    },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v;
      },
      expression: "data"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用"), _c('code', [_vm._v("v-model")]), _vm._v("实现数据的双向绑定。不同页面使用该组件时，需设定不同的id值加以区分。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };