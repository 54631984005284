var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("倒计时按钮")]), _c('count-down-button', {
    attrs: {
      "countTime": "10"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("提示")]), _vm._v(" 你可以将 "), _c('code', [_vm._v("autoCountDown")]), _vm._v(" 属性设置为 "), _c('code', [_vm._v("false")]), _vm._v("，即可手动调用开始倒计时方法 "), _c('code', [_vm._v("startCountDown()")]), _vm._v("，用于验证手机号或发送短信成功后开始倒计时等场景。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("methods")]), _c('Table', {
    attrs: {
      "columns": _vm.methods,
      "data": _vm.data3,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };