var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("通过流程key直接发起最新版本")]), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 示例（此处传入的属性流程key为请假申请leave，传入actBusinessId为测试数据123456，所以会报错）： ")]), _c('Button', {
    attrs: {
      "loading": _vm.processLoading
    },
    on: {
      "click": function ($event) {
        _vm.showProcessStart = true;
      }
    }
  }, [_vm._v("发起流程")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定（组件显示），显示组件前记得传入有效属性值：processKey和actBusinessId。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('process-start', {
    attrs: {
      "processKey": "leave",
      "actBusinessId": "123456"
    },
    on: {
      "on-loading": function ($event) {
        _vm.processLoading = true;
      },
      "on-loaded": function ($event) {
        _vm.processLoading = false;
      }
    },
    model: {
      value: _vm.showProcessStart,
      callback: function ($$v) {
        _vm.showProcessStart = $$v;
      },
      expression: "showProcessStart"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };