import { props, events, methods } from "./columns";
import processCancel from "@/views/my-components/xboot/process-cancel";
export default {
  components: {
    processCancel
  },
  data() {
    return {
      props: props,
      events: events,
      methods: methods,
      showProcessCancel: false,
      data1: [{
        name: "processKey",
        desc: "流程标识Key，显示组件前务必给该属性传入processKey数据值",
        type: "String",
        value: "空"
      }, {
        name: "procInstId",
        desc: "流程实例表id，显示组件前务必给该属性传入procInstId数据值",
        type: "String",
        value: "空"
      }],
      data2: [{
        name: "on-submit",
        type: "Boolean",
        value: "仅成功提交申请触发返回true，用于刷新表单显示审批状态"
      }]
    };
  },
  methods: {},
  mounted() {}
};