var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("部门树选择")]), _c('department-tree-choose', {
    ref: "depTree",
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "multiple": ""
    },
    on: {
      "on-change": _vm.handleSelectDepTree
    }
  }), _c('br'), _vm._v(" " + _vm._s(_vm.selectDeps) + " "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("样式冲突")]), _vm._v(" 在 "), _c('code', [_vm._v("FormItem")]), _vm._v(" 中使用时，建议在该标签上加上 "), _c('code', [_vm._v("class=\"form-noheight\"")]), _vm._v("。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("methods")]), _c('Table', {
    attrs: {
      "columns": _vm.methods,
      "data": _vm.data3,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };