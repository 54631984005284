var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("扩展图标XIcon")]), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v("Ionicons：")]), _c('XIcon', {
    attrs: {
      "type": "md-ionic",
      "size": "26"
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin": "10px 0"
    }
  }, [_vm._v("iconfont：")]), _c('XIcon', {
    attrs: {
      "type": "iconfont icon-github",
      "size": "26"
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin": "10px 0"
    }
  }, [_vm._v("URL：")]), _c('XIcon', {
    attrs: {
      "type": "https://ooo.0o0.ooo/2019/04/28/5cc5a71a6e3b6.png",
      "size": "30"
    }
  }), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin": "10px 0"
    }
  }, [_vm._v("Base64：")]), _c('XIcon', {
    attrs: {
      "type": _vm.base64,
      "size": "26"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，已全局挂载直接使用即可，兼容Ionicons、iconfont、图片链接URL、Base64。"), _c('code', [_vm._v(_vm._s(_vm.code))]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };