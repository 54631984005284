var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("用户抽屉选择")]), _c('user-choose', {
    ref: "userChoose",
    attrs: {
      "text": "点我选择用户"
    },
    model: {
      value: _vm.selectUsers,
      callback: function ($$v) {
        _vm.selectUsers = $$v;
      },
      expression: "selectUsers"
    }
  }), _c('br'), _vm._v(" " + _vm._s(_vm.selectUsers) + " "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。"), _c('br'), _vm._v("绑定值为用户对象数组，需包含用户id、nickname和username："), _c('code', [_vm._v("[{id: ID, username: 账号, nickname: 昵称}]")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("methods")]), _c('Table', {
    attrs: {
      "columns": _vm.methods,
      "data": _vm.data3,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };