import { unlock } from "@/api/index";
export default {
  name: "checkPassword",
  data() {
    return {
      loading: false,
      showModal: false,
      // 密码验证
      password: ""
    };
  },
  methods: {
    checkPass() {
      this.loading = true;
      unlock({
        password: this.password
      }).then(res => {
        this.loading = false;
        if (res.success) {
          this.showModal = false;
          this.$emit("on-success", true);
        }
      });
    },
    show() {
      this.password = "";
      this.showModal = true;
    }
  }
};