import update from "./update";
import loading from "./components/loading";
import countDownButton from "./components/countDownButton";
import iconChoose from "./components/iconChoose";
import XIconComponent from "./components/XIconComponent";
import dict from "./components/dict";
import customList from "./components/customList";
import departmentChoose from "./components/departmentChoose";
import departmentTreeChoose from "./components/departmentTreeChoose";
import userChoose from "./components/userChoose";
import userSelect from "./components/userSelect";
import materialCenter from "./components/materialCenter";
import uploadPicInput from "./components/uploadPicInput";
import uploadPicThumb from "./components/uploadPicThumb";
import fileUpload from "./components/fileUpload";
import checkPassword from "./components/checkPassword";
import setPassword from "./components/setPassword";
import editor from "./components/editor";
import monaco from "./components/monaco";
import mapLocate from "./components/mapLocate";
import processChoose from "./components/processChoose";
import processStart from "./components/processStart";
import processCancel from "./components/processCancel";
import card1 from "./components/card1";
import card2 from "./components/card2";
import card3 from "./components/card3";
import card4 from "./components/card4";
import countUp from "./components/countUp";
export default {
  name: "xboot-components",
  components: {
    update,
    loading,
    countDownButton,
    iconChoose,
    XIconComponent,
    dict,
    departmentChoose,
    userChoose,
    departmentTreeChoose,
    materialCenter,
    uploadPicInput,
    uploadPicThumb,
    fileUpload,
    checkPassword,
    setPassword,
    customList,
    userSelect,
    editor,
    monaco,
    mapLocate,
    processChoose,
    processStart,
    processCancel,
    card1,
    card2,
    card3,
    card4,
    countUp
  },
  data() {
    return {
      maxHeight: 510,
      currName: "0"
    };
  },
  methods: {
    init() {
      let q = this.$route.query;
      if (q.menu) {
        this.currName = q.menu;
      }
    }
  },
  mounted() {
    this.init();
    this.maxHeight = Number(document.documentElement.clientHeight - 160) + "px";
  }
};