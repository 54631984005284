var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("卡片2")]), _c('Row', {
    staticClass: "n-m-r-0",
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "id": "card1",
      "icon": "ios-stats",
      "endVal": 123456,
      "title": "Icon演示"
    }
  })], 1), _c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "id": "card2",
      "backgroundImage": 'url(' + require('@/assets/dashboard/circle.svg') + ')',
      "image": require('@/assets/dashboard/fee.png'),
      "endVal": 123456,
      "title": "图片演示"
    }
  })], 1), _c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "id": "card3",
      "backgroundImage": 'url(' + require('@/assets/dashboard/balloon.svg') + ')',
      "icon": "logo-bitcoin",
      "endVal": 1234.56,
      "prefix": "$",
      "duration": 5,
      "decimalPlaces": 2,
      "title": "计数配置演示"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };