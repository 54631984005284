var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', [_c('Layout', [_c('Sider', {
    staticStyle: {
      "background": "#fff",
      "max-width": "220px",
      "flex": "0 0 220px"
    },
    attrs: {
      "hide-trigger": ""
    }
  }, [_c('div', {
    staticClass: "side-content",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_c('Menu', {
    attrs: {
      "active-name": _vm.currName,
      "theme": "light",
      "width": "auto",
      "open-names": []
    },
    on: {
      "on-select": function ($event) {
        _vm.currName = $event;
      }
    }
  }, [_c('MenuItem', {
    attrs: {
      "name": "0"
    }
  }, [_c('Icon', {
    attrs: {
      "type": "md-ribbon"
    }
  }), _c('Badge', {
    attrs: {
      "dot": "",
      "offset": [5, -3]
    }
  }, [_vm._v("更新日志")])], 1), _c('Submenu', {
    attrs: {
      "name": "1"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('Icon', {
    attrs: {
      "type": "md-ionic"
    }
  }), _c('Badge', {
    attrs: {
      "dot": "",
      "offset": [5, -3]
    }
  }, [_vm._v("XBoot通用组件")])], 1), _c('MenuItem', {
    attrs: {
      "name": "1-0"
    }
  }, [_vm._v("全局Loading加载动画")]), _c('MenuItem', {
    attrs: {
      "name": "1-1"
    }
  }, [_vm._v("倒计时按钮")]), _c('MenuItem', {
    attrs: {
      "name": "1-2"
    }
  }, [_vm._v("图标选择输入框")]), _c('MenuItem', {
    attrs: {
      "name": "1-14"
    }
  }, [_vm._v("扩展图标XIcon")]), _c('MenuItem', {
    attrs: {
      "name": "1-11"
    }
  }, [_vm._v("数据字典组件")]), _c('MenuItem', {
    attrs: {
      "name": "1-12"
    }
  }, [_vm._v(" 自定义列表选择 ")]), _c('MenuItem', {
    attrs: {
      "name": "1-3"
    }
  }, [_vm._v("部门级联选择")]), _c('MenuItem', {
    attrs: {
      "name": "1-4"
    }
  }, [_vm._v("部门树选择")]), _c('MenuItem', {
    attrs: {
      "name": "1-5"
    }
  }, [_vm._v("用户抽屉选择")]), _c('MenuItem', {
    attrs: {
      "name": "1-13"
    }
  }, [_vm._v(" 用户搜索+抽屉选择 ")]), _c('MenuItem', {
    attrs: {
      "name": "1-15"
    }
  }, [_c('Badge', {
    attrs: {
      "dot": "",
      "offset": [5, -3]
    }
  }, [_vm._v("素材中心[付费]")])], 1), _c('MenuItem', {
    attrs: {
      "name": "1-6"
    }
  }, [_c('Badge', {
    attrs: {
      "dot": "",
      "offset": [5, -3]
    }
  }, [_vm._v("图片上传输入框")])], 1), _c('MenuItem', {
    attrs: {
      "name": "1-7"
    }
  }, [_c('Badge', {
    attrs: {
      "dot": "",
      "offset": [5, -3]
    }
  }, [_vm._v("图片上传缩略图")])], 1), _c('MenuItem', {
    attrs: {
      "name": "1-10"
    }
  }, [_vm._v(" 文件上传/下载 ")]), _c('MenuItem', {
    attrs: {
      "name": "1-8"
    }
  }, [_vm._v("身份验证全屏弹框")]), _c('MenuItem', {
    attrs: {
      "name": "1-9"
    }
  }, [_vm._v("密码强度提示输入框")])], 2), _c('Submenu', {
    attrs: {
      "name": "3"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('Icon', {
    attrs: {
      "type": "ios-create"
    }
  }), _c('Badge', {
    attrs: {
      "dot": "",
      "offset": [5, -3]
    }
  }, [_vm._v("编辑器[付费]")])], 1), _c('MenuItem', {
    attrs: {
      "name": "3-1"
    }
  }, [_vm._v("wangEditor 富文本")]), _c('MenuItem', {
    attrs: {
      "name": "3-3"
    }
  }, [_vm._v("Monaco 代码编辑器")])], 2), _c('Submenu', {
    attrs: {
      "name": "4"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('Icon', {
    attrs: {
      "type": "md-globe"
    }
  }), _vm._v("地图组件 ")], 1), _c('MenuItem', {
    attrs: {
      "name": "4-1"
    }
  }, [_vm._v("地图选点")])], 2), _c('Submenu', {
    attrs: {
      "name": "2"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('Icon', {
    attrs: {
      "type": "md-git-compare"
    }
  }), _vm._v("工作流组件[付费] ")], 1), _c('MenuItem', {
    attrs: {
      "name": "2-1"
    }
  }, [_vm._v("工作流程选择发起")]), _c('MenuItem', {
    attrs: {
      "name": "2-2"
    }
  }, [_vm._v("通过流程key直接发起")]), _c('MenuItem', {
    attrs: {
      "name": "2-3"
    }
  }, [_vm._v("取消撤回申请")])], 2), _c('Submenu', {
    attrs: {
      "name": "5"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('Icon', {
    attrs: {
      "type": "md-albums"
    }
  }), _vm._v("Widget组件 ")], 1), _c('MenuItem', {
    attrs: {
      "name": "5-1"
    }
  }, [_vm._v("卡片1")]), _c('MenuItem', {
    attrs: {
      "name": "5-2"
    }
  }, [_vm._v("卡片2")]), _c('MenuItem', {
    attrs: {
      "name": "5-3"
    }
  }, [_vm._v("卡片3")]), _c('MenuItem', {
    attrs: {
      "name": "5-4"
    }
  }, [_vm._v("卡片4")]), _c('MenuItem', {
    attrs: {
      "name": "5-5"
    }
  }, [_vm._v("计数组件")])], 2)], 1)], 1)]), _c('Content', {
    style: {
      padding: '0 0 0 24px',
      minHeight: '280px',
      background: '#fff'
    }
  }, [_c('div', {
    staticClass: "component-content",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_vm.currName == '0' ? _c('div', [_c('update')], 1) : _vm._e(), _vm.currName == '1-0' ? _c('div', [_c('loading')], 1) : _vm._e(), _vm.currName == '1-1' ? _c('div', [_c('countDownButton')], 1) : _vm._e(), _vm.currName == '1-2' ? _c('div', [_c('iconChoose')], 1) : _vm._e(), _vm.currName == '1-14' ? _c('div', [_c('XIconComponent')], 1) : _vm._e(), _vm.currName == '1-11' ? _c('div', [_c('dict')], 1) : _vm._e(), _vm.currName == '1-12' ? _c('div', [_c('customList')], 1) : _vm._e(), _vm.currName == '1-3' ? _c('div', [_c('departmentChoose')], 1) : _vm._e(), _vm.currName == '1-4' ? _c('div', [_c('departmentTreeChoose')], 1) : _vm._e(), _vm.currName == '1-5' ? _c('div', [_c('userChoose')], 1) : _vm._e(), _vm.currName == '1-13' ? _c('div', [_c('userSelect')], 1) : _vm._e(), _vm.currName == '1-6' ? _c('div', [_c('uploadPicInput')], 1) : _vm._e(), _vm.currName == '1-7' ? _c('div', [_c('uploadPicThumb')], 1) : _vm._e(), _vm.currName == '1-10' ? _c('div', [_c('fileUpload')], 1) : _vm._e(), _vm.currName == '1-8' ? _c('div', [_c('checkPassword')], 1) : _vm._e(), _vm.currName == '1-9' ? _c('div', [_c('setPassword')], 1) : _vm._e(), _vm.currName == '1-15' ? _c('div', [_c('materialCenter')], 1) : _vm._e(), _vm.currName == '3-1' ? _c('div', [_c('editor')], 1) : _vm._e(), _vm.currName == '3-3' ? _c('div', [_c('monaco')], 1) : _vm._e(), _vm.currName == '4-1' ? _c('div', [_c('mapLocate')], 1) : _vm._e(), _vm.currName == '2-1' ? _c('div', [_c('processChoose')], 1) : _vm._e(), _vm.currName == '2-2' ? _c('div', [_c('processStart')], 1) : _vm._e(), _vm.currName == '2-3' ? _c('div', [_c('processCancel')], 1) : _vm._e(), _vm.currName == '5-1' ? _c('div', [_c('card1')], 1) : _vm._e(), _vm.currName == '5-2' ? _c('div', [_c('card2')], 1) : _vm._e(), _vm.currName == '5-3' ? _c('div', [_c('card3')], 1) : _vm._e(), _vm.currName == '5-4' ? _c('div', [_c('card4')], 1) : _vm._e(), _vm.currName == '5-5' ? _c('div', [_c('countUp')], 1) : _vm._e()])])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };