var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v("流程审批通用状态及结果保存至ActBusiness表中，添加业务时记得向该表中关联表ID等数据，参考开发文档工作流部分")]), _c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("工作流程选择发起")]), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 示例（此处传入actBusinessId属性值为测试数据123456，所以会报错）： ")]), _c('Button', {
    on: {
      "click": function ($event) {
        _vm.showProcessChoose = true;
      }
    }
  }, [_vm._v("发起流程")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定（组件显示），显示组件前记得传入有效属性值：actBusinessId。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('process-choose', {
    attrs: {
      "actBusinessId": "123456"
    },
    model: {
      value: _vm.showProcessChoose,
      callback: function ($$v) {
        _vm.showProcessChoose = $$v;
      },
      expression: "showProcessChoose"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };