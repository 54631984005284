var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Button', {
    attrs: {
      "countTime": _vm.countTime,
      "loading": _vm.loading,
      "type": _vm.type,
      "size": _vm.size,
      "ghost": _vm.ghost,
      "disabled": _vm.disabled || _vm.clicked,
      "icon": _vm.icon,
      "shape": _vm.shape,
      "long": _vm.long
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(_vm._s(_vm.buttonText))])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };