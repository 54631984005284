var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("Monaco 代码编辑器")]), _c('Alert', {
    attrs: {
      "type": "info",
      "show-icon": ""
    }
  }, [_vm._v(" 基于 "), _c('a', {
    attrs: {
      "href": "https://github.com/microsoft/monaco-editor",
      "target": "_blank"
    }
  }, [_vm._v("Monaco Editor")]), _vm._v(" 封装，已配置好中文，支持v-model双向绑定，属性配置等。 ")]), _c('monaco', {
    staticStyle: {
      "width": "1000px"
    },
    attrs: {
      "language": "javascript",
      "defaultTheme": "vs-dark",
      "title": "编辑器标题",
      "height": "300px"
    },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v;
      },
      expression: "data"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。单页面同时使用两个及以上该组件时，需设定不同的id值加以区分。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("methods")]), _c('Table', {
    attrs: {
      "columns": _vm.methods,
      "data": _vm.data3,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };