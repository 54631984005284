var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Drawer', {
    attrs: {
      "title": "选择流程",
      "closable": "",
      "width": "820",
      "draggable": ""
    },
    model: {
      value: _vm.processModalVisible,
      callback: function ($$v) {
        _vm.processModalVisible = $$v;
      },
      expression: "processModalVisible"
    }
  }, [_c('div', {
    staticClass: "apply-operation"
  }, [_c('div', [_c('Form', {
    ref: "searchProcessForm",
    attrs: {
      "model": _vm.searchProcessForm,
      "inline": "",
      "label-width": 70
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "流程名称",
      "prop": "name"
    }
  }, [_c('Input', {
    staticStyle: {
      "width": "140px"
    },
    attrs: {
      "type": "text",
      "clearable": "",
      "placeholder": "请输入流程名"
    },
    model: {
      value: _vm.searchProcessForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchProcessForm, "name", $$v);
      },
      expression: "searchProcessForm.name"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "所属分类",
      "prop": "category"
    }
  }, [_c('Cascader', {
    staticStyle: {
      "width": "140px"
    },
    attrs: {
      "data": _vm.category,
      "load-data": _vm.loadData,
      "change-on-select": "",
      "filterable": "",
      "clearable": "",
      "placeholder": "请选择分类"
    },
    on: {
      "on-change": _vm.handleChangeCat
    },
    model: {
      value: _vm.selectCat,
      callback: function ($$v) {
        _vm.selectCat = $$v;
      },
      expression: "selectCat"
    }
  })], 1), _c('FormItem', {
    staticClass: "br",
    staticStyle: {
      "margin-left": "-70px"
    }
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "icon": "ios-search"
    },
    on: {
      "click": _vm.searchProcess
    }
  }, [_vm._v("搜索")]), _c('Button', {
    on: {
      "click": _vm.handleResetProcess
    }
  }, [_vm._v("重置")]), _c('i-switch', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "size": "large"
    },
    on: {
      "on-change": _vm.searchProcess
    },
    model: {
      value: _vm.searchProcessForm.showLatest,
      callback: function ($$v) {
        _vm.$set(_vm.searchProcessForm, "showLatest", $$v);
      },
      expression: "searchProcessForm.showLatest"
    }
  }, [_c('span', {
    attrs: {
      "slot": "open"
    },
    slot: "open"
  }, [_vm._v("最新")]), _c('span', {
    attrs: {
      "slot": "close"
    },
    slot: "close"
  }, [_vm._v("全部")])])], 1)], 1)], 1), _c('div', [_c('RadioGroup', {
    attrs: {
      "type": "button"
    },
    model: {
      value: _vm.showType,
      callback: function ($$v) {
        _vm.showType = $$v;
      },
      expression: "showType"
    }
  }, [_c('Radio', {
    attrs: {
      "title": "缩略图",
      "label": "thumb"
    }
  }, [_c('Icon', {
    attrs: {
      "type": "ios-apps"
    }
  })], 1), _c('Radio', {
    attrs: {
      "title": "列表",
      "label": "list"
    }
  }, [_c('Icon', {
    attrs: {
      "type": "md-list"
    }
  })], 1)], 1)], 1)]), _vm.showType == 'thumb' ? _c('div', {
    staticClass: "process-wrapper",
    style: {
      maxHeight: _vm.maxHeight + 'px'
    }
  }, [_vm._l(_vm.processData, function (item, i) {
    return _c('Card', {
      key: i,
      staticClass: "process-card"
    }, [_c('div', {
      staticClass: "content",
      on: {
        "click": function ($event) {
          return _vm.showApply(item);
        }
      }
    }, [_c('div', {
      staticClass: "other"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(i + 1) + ". " + _vm._s(item.name))]), _c('div', {
      staticClass: "key"
    }, [_vm._v(_vm._s(item.description))]), _c('div', {
      staticClass: "info"
    }, [_vm._v(" 版本：v." + _vm._s(item.version) + " 所属分类：" + _vm._s(item.categoryTitle) + " ")])])])]);
  }), _vm.processLoading ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }) : _vm._e()], 2) : _vm._e(), _vm.showType == 'list' ? _c('Table', {
    ref: "processTable",
    attrs: {
      "height": _vm.maxHeight,
      "loading": _vm.processLoading,
      "border": "",
      "columns": _vm.processColumns,
      "data": _vm.processData
    }
  }) : _vm._e(), _c('Row', {
    staticClass: "process-choose-drawer-footer"
  }, [_c('Page', {
    attrs: {
      "current": _vm.searchProcessForm.pageNumber,
      "page-size": _vm.searchProcessForm.pageSize,
      "page-size-opts": [10, 20, 50],
      "size": "small",
      "show-elevator": "",
      "show-sizer": ""
    },
    on: {
      "on-change": _vm.changeProcessPage,
      "on-page-size-change": _vm.changeProcessPageSize
    }
  })], 1)], 1), _c('Modal', {
    attrs: {
      "title": "提交申请",
      "mask-closable": false,
      "width": 500
    },
    model: {
      value: _vm.applyModalVisible,
      callback: function ($$v) {
        _vm.applyModalVisible = $$v;
      },
      expression: "applyModalVisible"
    }
  }, [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "label-width": 85,
      "rules": _vm.formValidate
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "标题",
      "prop": "title"
    }
  }, [_c('Input', {
    attrs: {
      "placeholder": "请输入标题",
      "clearable": ""
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('FormItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAssign,
      expression: "showAssign"
    }],
    attrs: {
      "label": "选择审批人",
      "prop": "assignees",
      "error": _vm.error
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": "请选择或输入搜索",
      "filterable": "",
      "clearable": "",
      "multiple": "",
      "loading": _vm.userLoading
    },
    model: {
      value: _vm.form.assignees,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assignees", $$v);
      },
      expression: "form.assignees"
    }
  }, _vm._l(_vm.assigneeList, function (item, i) {
    return _c('Option', {
      key: i,
      attrs: {
        "value": item.id,
        "label": item.nickname
      }
    }, [_c('span', {
      staticStyle: {
        "margin-right": "10px"
      }
    }, [_vm._v(_vm._s(item.nickname))]), _c('span', {
      staticStyle: {
        "color": "#ccc"
      }
    }, [_vm._v(_vm._s(item.username))])]);
  }), 1)], 1), _c('FormItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isCustom,
      expression: "isCustom"
    }],
    attrs: {
      "label": "自定义搜索选择审批人",
      "prop": "assignees",
      "error": _vm.error
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": "请输入用户名搜索选择用户",
      "filterable": "",
      "remote": "",
      "multiple": "",
      "remote-method": _vm.searchUser,
      "loading": _vm.userLoading
    },
    model: {
      value: _vm.form.assignees,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assignees", $$v);
      },
      expression: "form.assignees"
    }
  }, _vm._l(_vm.userList, function (item, i) {
    return _c('Option', {
      key: i,
      attrs: {
        "value": item.id,
        "label": item.nickname
      }
    }, [_c('span', {
      staticStyle: {
        "margin-right": "10px"
      }
    }, [_vm._v(_vm._s(item.nickname))]), _c('span', {
      staticStyle: {
        "color": "#ccc"
      }
    }, [_vm._v(_vm._s(item.username))])]);
  }), 1)], 1), _c('FormItem', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isGateway,
      expression: "isGateway"
    }],
    attrs: {
      "label": "下一审批人"
    }
  }, [_c('span', [_vm._v("分支网关处暂不支持自定义选择下一审批人，将发送给下一节点所有人")])]), _c('FormItem', {
    attrs: {
      "label": "优先级",
      "prop": "priority"
    }
  }, [_c('dict', {
    attrs: {
      "dict": "priority"
    },
    model: {
      value: _vm.form.priority,
      callback: function ($$v) {
        _vm.$set(_vm.form, "priority", $$v);
      },
      expression: "form.priority"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "消息通知"
    }
  }, [_c('Checkbox', {
    model: {
      value: _vm.form.sendMessage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sendMessage", $$v);
      },
      expression: "form.sendMessage"
    }
  }, [_vm._v("站内消息通知")]), _c('Checkbox', {
    model: {
      value: _vm.form.sendSms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sendSms", $$v);
      },
      expression: "form.sendSms"
    }
  }, [_vm._v("短信通知")]), _c('Checkbox', {
    model: {
      value: _vm.form.sendEmail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sendEmail", $$v);
      },
      expression: "form.sendEmail"
    }
  }, [_vm._v("邮件通知")])], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        _vm.applyModalVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.handelSubmit
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };