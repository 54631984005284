import { getProcessDataList, getFirstNode, startBusiness, initActCategory, loadActCategory } from "@/api/activiti";
import { searchUserByName } from "@/api/index";
import dict from "@/views/my-components/xboot/dict";
export default {
  name: "processChoose",
  components: {
    dict
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    actBusinessId: String
  },
  data() {
    return {
      processModalVisible: this.value,
      maxHeight: 510,
      userLoading: false,
      error: "",
      showAssign: false,
      isGateway: false,
      isCustom: false,
      submitLoading: false,
      processLoading: true,
      // 表单加载状态
      applyModalVisible: false,
      selectCat: [],
      category: [],
      processData: [],
      showType: "thumb",
      searchProcessForm: {
        showLatest: true,
        filter: true,
        name: "",
        status: "1",
        // 激活状态
        pageNumber: 1,
        // 当前页数
        pageSize: 10,
        // 页面大小
        sort: "createTime",
        // 默认排序字段
        order: "desc" // 默认排序方式
      },

      form: {
        title: "",
        procDefId: "",
        assignees: [],
        priority: "0",
        sendMessage: true,
        sendSms: true,
        sendEmail: true
      },
      formValidate: {
        // 表单验证规则
        title: [{
          required: true,
          message: "请输入",
          trigger: "change"
        }],
        priority: [{
          required: true,
          message: "请输入",
          trigger: "change"
        }]
      },
      assigneeList: [],
      processColumns: [{
        type: "index",
        width: 60,
        align: "center"
      }, {
        title: "名称",
        key: "name",
        width: 150,
        sortable: true
      }, {
        title: "备注描述",
        key: "description",
        width: 150,
        sortable: true
      }, {
        title: "所属分类",
        key: "categoryTitle",
        width: 150,
        sortable: true
      }, {
        title: "版本",
        key: "version",
        align: "center",
        sortable: true,
        render: (h, params) => {
          let re = "";
          if (params.row.version) {
            re = "v." + params.row.version;
          }
          return h("div", re);
        }
      }, {
        title: "操作",
        key: "action",
        width: 135,
        align: "center",
        fixed: "right",
        render: (h, params) => {
          return h("div", [h("Button", {
            props: {
              type: "info",
              size: "small"
            },
            on: {
              click: () => {
                this.showApply(params.row);
              }
            }
          }, "选择该流程")]);
        }
      }],
      userList: []
    };
  },
  methods: {
    init() {
      this.getProcessList();
      this.initCategoryData();
    },
    getProcessList() {
      this.processLoading = true;
      getProcessDataList(this.searchProcessForm).then(res => {
        this.processLoading = false;
        if (res.success) {
          this.processData = res.result.content;
        }
      });
    },
    changeProcessPage(v) {
      this.searchProcessForm.pageNumber = v;
      this.getProcessList();
    },
    changeProcessPageSize(v) {
      this.searchProcessForm.pageSize = v;
      this.getProcessList();
    },
    searchProcess() {
      this.searchProcessForm.pageNumber = 1;
      this.getProcessList();
    },
    searchUser(v) {
      if (!v) {
        return;
      }
      this.userLoading = true;
      searchUserByName(v).then(res => {
        this.userLoading = false;
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    initCategoryData() {
      initActCategory().then(res => {
        if (res.success) {
          res.result.forEach(function (e) {
            if (e.isParent) {
              e.value = e.id;
              e.label = e.title;
              e.loading = false;
              e.children = [];
            } else {
              e.value = e.id;
              e.label = e.title;
            }
            if (e.status == -1) {
              e.label = "[已禁用] " + e.label;
              e.disabled = true;
            }
          });
          this.category = res.result;
        }
      });
    },
    loadData(item, callback) {
      item.loading = true;
      loadActCategory(item.value).then(res => {
        item.loading = false;
        if (res.success) {
          res.result.forEach(function (e) {
            if (e.isParent) {
              e.value = e.id;
              e.label = e.title;
              e.loading = false;
              e.children = [];
            } else {
              e.value = e.id;
              e.label = e.title;
            }
            if (e.status == -1) {
              e.label = "[已禁用] " + e.label;
              e.disabled = true;
            }
          });
          item.children = res.result;
          callback();
        }
      });
    },
    handleChangeCat(value, selectedData) {
      let categoryId = "";
      // 获取最后一个值
      if (value && value.length > 0) {
        categoryId = value[value.length - 1];
      }
      this.searchProcessForm.categoryId = categoryId;
    },
    handleResetProcess() {
      this.$refs.searchProcessForm.resetFields();
      this.searchProcessForm.categoryId = "";
      this.selectCat = [];
      // 重新加载数据
      this.getProcessList();
    },
    showApply(v) {
      if (!v.routeName) {
        this.$Message.warning("该流程信息未完善，请于流程管理中编辑流程完善信息");
        return;
      }
      this.form.procDefId = v.id;
      this.form.title = v.name;
      // 加载审批人
      this.userLoading = true;
      getFirstNode(v.id).then(res => {
        this.userLoading = false;
        if (res.success) {
          this.error = "";
          if (res.result.type == 3 || res.result.type == 4) {
            this.isGateway = true;
            this.form.firstGateway = true;
            this.showAssign = false;
            this.isCustom = false;
            return;
          }
          if (res.result.type == 5) {
            this.isCustom = true;
            this.isGateway = false;
            this.form.firstGateway = false;
            this.showAssign = false;
            return;
          }
          if (res.result.type == 1) {
            this.showAssign = true;
            this.isGateway = false;
            this.form.firstGateway = false;
            this.isCustom = false;
            if (res.result.users && res.result.users.length > 0) {
              this.assigneeList = res.result.users;
              // 默认勾选
              let ids = [];
              res.result.users.forEach(e => {
                ids.push(e.id);
              });
              this.form.assignees = ids;
              this.showAssign = true;
            } else {
              this.form.assignees = [];
              this.showAssign = true;
              this.error = '请进入"流程管理"为审批节点分配候选审批人员';
            }
          }
        }
      });
      this.applyModalVisible = true;
    },
    handelSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if ((this.showAssign || this.isCustom) && this.form.assignees.length < 1) {
            this.error = "请至少选择一个审批人";
            return;
          } else {
            this.error = "";
          }
          this.form.id = this.actBusinessId;
          if (!this.form.id) {
            this.$Message.error("请传入业务表ActBusiness的ID");
            return;
          }
          this.submitLoading = true;
          startBusiness(this.form).then(res => {
            this.submitLoading = false;
            if (res.success) {
              this.$Message.success("操作成功");
              this.applyModalVisible = false;
              this.processModalVisible = false;
              this.$emit("on-submit", true);
              // 重置
              this.form.id = "";
            }
          });
        }
      });
    },
    setCurrentValue(value) {
      if (value === this.processModalVisible) {
        return;
      }
      this.processModalVisible = value;
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    },
    processModalVisible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    // 计算高度
    this.maxHeight = Number(document.documentElement.clientHeight - 180);
    this.init();
  }
};