var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("数组字典组件")]), _c('dict', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "dict": "sex",
      "transfer": ""
    }
  }), _c('br'), _c('dict', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "dict": "permission_type",
      "transfer": ""
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，传入 "), _c('code', [_vm._v("dict")]), _vm._v("属性获取指定的数据字典类型， 使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };