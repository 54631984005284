import { props, events, methods } from "./columns";
import processStart from "@/views/my-components/xboot/process-start";
export default {
  components: {
    processStart
  },
  data() {
    return {
      props: props,
      events: events,
      methods: methods,
      showProcessStart: false,
      processLoading: false,
      data1: [{
        name: "value",
        desc: "绑定的值，可使用 v-model 双向绑定",
        type: "String",
        value: "空"
      }, {
        name: "processKey",
        desc: "流程标识Key，显示组件前务必给该属性传入processKey数据值",
        type: "String",
        value: "空"
      }, {
        name: "actBusinessId",
        desc: "流程关联业务表id，显示组件前务必给该属性传入actBusinessId数据值",
        type: "String",
        value: "空"
      }],
      data2: [{
        name: "on-loading",
        type: "Boolean",
        value: "加载中状态，传入key后需加载流程信息，加载中触发返回true"
      }, {
        name: "on-loaded",
        type: "Boolean",
        value: "加载完毕状态，传入key后需加载流程信息，加载完毕触发返回true"
      }, {
        name: "on-submit",
        type: "Boolean",
        value: "仅成功提交申请触发返回true，用于刷新表单显示审批状态"
      }]
    };
  },
  methods: {},
  mounted() {}
};